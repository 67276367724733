<template>
    <div class="details">
      <van-cell-group inset class="details_cell_1">
        <van-cell value="权益充值" />
        <div class="goods_name">
          <van-image width="6rem" height="6rem" :src="logoUrl" />
          <span>{{ fullName }}</span>
        </div>
      </van-cell-group>
      <van-cell-group inset class="details_cell_2">
        <van-cell title=" 商品金额 ">
          <span>￥{{ faceValue }}</span>
        </van-cell>
        <van-cell title="支付金额 ">
          <span style="color:red; font-size:1em;" v-show="!flg">已优惠￥{{ deductPrice }}</span>&nbsp;
          <span style="color:#000; font-size:1.5em; font-weight:500;">￥{{ retailPrice }}</span>
        </van-cell>
        <van-field v-model="account" type="tel " label="充值账号 " placeholder="请输入充值账号 " />
        <van-cell title="优惠券 ">
          <!-- <span style="color:red; font-size:1em; font-weight:600;">使用优惠券</span> -->
          <span style="color:#3c9cff; font-size:1em; font-weight:600;" @click="changCouPon" v-if="flg">使用优惠券</span>
          <span style="color:red; font-size:1em; font-weight:600;" v-else>已使用优惠券</span>
        </van-cell>
      </van-cell-group>
      <van-cell-group inset class="details_cell_3">
        <p style="color:#000;font-size:1.2em;margin-bottom:1em; font-weight:500;">产品详情</p>
        <p v-html="this.instructions"></p>
      </van-cell-group>
      <van-tabbar v-model="active">
        <van-tabbar-item icon="manager-o">客服</van-tabbar-item>
        <van-tabbar-item icon="orders-o" @click="myorder">我的订单</van-tabbar-item>
        <van-button round type="danger" size="large" @click="Submit">提交订单</van-button>
      </van-tabbar>
      <van-dialog v-model="accountDialog" show-cancel-button confirm-button-text="确认无误" cancel-button-text="更改号码"
        :before-close="confirmed">
        <div style=" display: flex;flex-direction: column;align-items: center; margin:2.2em 0; line-height:3em;">
          <p>您当前的充值号码为：</p>
          <p style="font-size:1.6em; font-weight:600">{{ account }}</p>
        </div>
      </van-dialog>
    </div>
  </template>
  <script>
  import {
    api_goodSkuDetails, api_jsSdkconfig, api_micropay, api_linkGoodSkuDetails,
    api_phone_token
  } from "@/api/api.js"
  import { getQueryVariable, } from "@/util/utils.js"
  import wx from 'weixin-js-sdk'
  export default {
    data () {
      return {
        SkuId: '',
        faceValue: "",
        fullName: "",
        retailPrice: 0,
        account: "",
        logoUrl: "",
        goodsId: "",
        linkNumber: "",
        instructions: "",
        active: "0",
        activityId: "",
        accountDialog: false,
        location: "",
        accessToken: "",
        productCode:"",
        deductPrice:0,
        flg:true
      }
    },
    activated () {
    // 显示时
      this.deductPrice = sessionStorage.getItem('deductPrice')
      if(this.deductPrice){
        this.flg=!this.flg
        this.retailPrice=sessionStorage.getItem('retailPrice')-this.deductPrice
        console.log(sessionStorage.getItem('retailPrice')-this.deductPrice)
      }
    },
    created () {
      this.location = window.location.href
      this.SkuId = getQueryVariable("id")
      this.supplierId = getQueryVariable("supplierId")
      this.accessToken = sessionStorage.getItem('accessToken')
      if (getQueryVariable("link") == false) {
        this.linkNumber = ""
      } else {
        this.linkNumber = getQueryVariable("link")
      }
      this.activityId = getQueryVariable("activityId")
      if (this.linkNumber) {
        console.log(11)
        this.getLinkSkuDetails()
      } else {
        this.getSkuDetails()
      }
      this.getjsSDK()
      localStorage.setItem('redirect_uri', window.location.href)
    },
    methods: {
      getjsSDK () {
        api_jsSdkconfig().then(res => {
          if (res.data.code == 0) {
            wx.config({
              debug: false,
              appId: res.data.data.appId,
              timestamp: res.data.data.timestamp,
              nonceStr: res.data.data.noncestr,
              signature: res.data.data.signature,
              jsApiList: ['chooseWXPay']
            })
          }
        })
      },
      getSkuDetails () {
        api_goodSkuDetails(this.SkuId, this.supplierId).then(res => {
          if (res.data.code == 0) {
            let message = res.data.data.instructions;
            this.logoUrl = res.data.data.showImage
            this.instructions = message.replace(/\n/g, "<br/>");
            for (const item of res.data.data.details) {
              for (const val of item.skus) {
                this.fullName = val.fullName
                this.faceValue = val.faceValue
                this.retailPrice = val.retailPrice
                this.goodsId = val.goodsId
                this.productCode=val.productNumber
              }
            }
          } else {
            console.log(res.data.message)
          }
        })
      },
      getLinkSkuDetails () {
        const params = {
          linkNumber: this.linkNumber
        }
        api_linkGoodSkuDetails(params).then(res => {
          if (res.data.code == 0) {
            let message = res.data.data.instructions;
            this.logoUrl = res.data.data.showImage
            this.instructions = message.replace(/\n/g, "<br/>");
            for (const item of res.data.data.details) {
              for (const val of item.skus) {
                this.fullName = val.fullName
                this.faceValue = val.faceValue
                this.retailPrice = val.retailPrice
                this.goodsId = val.goodsId
                this.productCode=val.productNumber
              }
            }
          } else {
            console.log(res.data.message)
          }
        })
      },
      Submit () {
        if (this.account == '') {
          this.$toast('请输入充值账号');
        }
        else {
          this.accountDialog = true
        }
      },
      confirmed (e, done) {
        if (e === 'confirm') {
          const phone = {
            phone: this.account
          }
          api_phone_token(phone).then(res => {
            if (res.data.code == 0) {
              sessionStorage.setItem('accessToken', 'yh' + ' ' + res.data.data.accessToken)
              const params = {
                account: this.account,
                sendEmail: null,
                remark: null,
                goodsId: this.goodsId,
                skuId: this.SkuId,
                supplierId: this.supplierId,
                activityId: this.activityIdId,
                linkNumber: this.linkNumber,
                sourceUrl: this.location,
                couponID:sessionStorage.getItem('couponID'),
                tradeType: 1
              }
              console.log('params', params)
              api_micropay(params).then(res => {
                if (res.status == 200) {
                  console.log(res.data.mwebUrl)
                  sessionStorage.clear()
                  window.location.href = res.data.mwebUrl
                } else {
                  this.$toast.fail('支付失败' + res.data.message);
                  return done()
                }
              }).catch((err) => {
                alert(err);
              })
            }
          })
        }
        else {
          this.accountDialog = false
          return done()
        }
      },
      myorder () {
        this.$router.push({
          path: '/queryorder',
          query: {
            supplierId: this.supplierId,
          }
        })
      },
      changCouPon(){
        if(this.account){
          sessionStorage.setItem('retailPrice',this.retailPrice)
          this.$router.push({
          path: '/couponPage',
          query: {
            productCode: this.productCode,
            orderAmount: this.retailPrice,
            phone:this.account
          }
        })
        }else{
          alert('请先输入充值账号')
        }
       
      },
    },
    onbeforeunload() {
      console.log(1111)
      // 清除sessionStorage
      if (this.someDataToClear) {
            sessionStorage.clear();
      }
    },
  }
  </script>
  <style lang="scss" scoped>
  .details {
    height: 100%;
    background: rgb(247, 245, 245);
    overflow: scroll;
  
    .details_cell_1 {
      margin-top: 1.5em;
  
      .van-cell__value--alone {
        color: #646566;
        font-size: 1.5em;
        font-weight: 600;
      }
  
      .goods_name {
        padding: 1em;
        display: flex;
        align-items: center;
  
        span {
          margin-left: 1em;
          color: #646566;
          font-size: 2em;
          font-weight: 600;
        }
      }
    }
  
    .details_cell_2 {
      margin-top: 1.5em;
    }
  
    .details_cell_3 {
      margin-top: 1.5em;
      margin-bottom: 5em;
      padding: 2em 1em 2em 1em;
      font-size: 1.4em;
      color: #646566;
      line-height: 1.8em;
    }
  
    .van-tabbar {
      height: 5.5em;
  
      .van-tabbar-item {
        min-width: 80px;
      }
  
      .van-button--large {
        height: 2.5em;
        margin: 0.6em 0.3em 0.6em 0.2em;
        border: none;
        background: linear-gradient(to right, #ff8917, #ff6034);
  
        .van-button__text {
          font-size: 1em;
        }
      }
    }
  }
   ::v-deep .van-field__control{
    text-align: right !important;
  }
  </style>
  
  